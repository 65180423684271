import {
  PocInfoLink,
  PocInfoMail,
  PocInformationSafetyMail,
  PocInfoTel,
  PocOmbudsLink,
  PocOmbudsMail,
  PocOmbudsTel,
} from '@features/legal';

export const pocComponents = Object.freeze({
  'poc-information-safety-mail': <PocInformationSafetyMail />,

  'poc-info-link': <PocInfoLink />,
  'poc-info-mail': <PocInfoMail />,
  'poc-info-tel': <PocInfoTel />,

  'poc-ombuds-link': <PocOmbudsLink />,
  'poc-ombuds-mail': <PocOmbudsMail />,
  'poc-ombuds-tel': <PocOmbudsTel />,
});
