import { Box } from '@chakra-ui/react';
import { TAppBoxProps } from '@codeer/handypark-web-common';

type TAppContainerLegalContentProps = TAppBoxProps;

export const AppContainerLegalContent = ({ children, ...rest }: TAppContainerLegalContentProps) => {
  return (
    <Box px={{ base: 0, md: 4 }} display={'flex'} flexDirection={'column'} gap={6} {...rest}>
      {children}
    </Box>
  );
};
