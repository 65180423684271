import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppButton,
  AppHeading,
  HEADING_STYLING_KEYS,
  Paragraph,
} from '@codeer/handypark-web-common';
import { HELP_AND_SUPPORT_ROUTES } from '@features/help-and-support';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const LandingHelpAndSupportContent = () => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'onboarding.help-and-support' });

  /* --- HOOKS --- */
  const navigate = useNavigate();

  return (
    <Box display={'flex'} flexDirection={'column'} gap={4}>
      <AppHeading qa={'app-heading-main-title'} as={HEADING_STYLING_KEYS.H2}>
        {t('title')}
      </AppHeading>
      <Paragraph>{t('content')}</Paragraph>
      <AppButton mt={4} onClick={() => navigate(HELP_AND_SUPPORT_ROUTES().HELP_AND_SUPPORT())}>
        {t('button')}
      </AppButton>
    </Box>
  );
};
