import { AppContainer, TAppContainerProps } from '@codeer/handypark-web-common';

type TAppContainerLegalProps = TAppContainerProps & {
  id: string;
};

export const AppContainerLegal = ({ id, children, ...rest }: TAppContainerLegalProps) => {
  return (
    <AppContainer pt={8} pb={8} display={'flex'} flexDirection={'column'} gap={6} id={id} {...rest}>
      {children}
    </AppContainer>
  );
};
