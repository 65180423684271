import { Badge, BadgeProps } from '@chakra-ui/react';
import { Qa } from '@codeer/handypark-common';
import { COLOR_SCHEMES } from '@codeer/handypark-web-common';

type TAppBadgeProps = Qa & BadgeProps;

// TODO: External theme file
export const AppBadge = ({ qa = 'app-badge', sx, children, ...rest }: TAppBadgeProps) => {
  return (
    <Badge
      fontSize={'sm'}
      py={1}
      px={2}
      border={'0.0625rem solid'}
      borderColor={`${COLOR_SCHEMES.PRIMARY}.main`}
      borderRadius={'sm'}
      background={`${COLOR_SCHEMES.PRIMARY}.background`}
      color={`${COLOR_SCHEMES.PRIMARY}.main`}
      display={'inline-flex'}
      flexDirection={'row'}
      alignItems={'center'}
      gap={1.5}
      textTransform={'none'}
      sx={{
        svg: { fontSize: '0.8em' },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Badge>
  );
};
