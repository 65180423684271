import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  APP_BUTTON_VARIANTS,
  AppButton,
  AppHeading,
  HEADING_STYLING_KEYS,
  Paragraph,
} from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

export const LandingHeroContent = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'onboarding.hero',
  });

  const navigateToWebApp = () => {
    window.location.href = window.HP.FRONTOFFICE_URL;
  };

  return (
    <>
      <AppHeading
        qa={'app-heading-main-title'}
        as={HEADING_STYLING_KEYS.H1}
        textStyle={HEADING_STYLING_KEYS.H2}
      >
        {t('title')}
      </AppHeading>
      <Paragraph maxWidth={{ base: 'none', md: '18.75rem' }}>{t('content')}</Paragraph>
      <AppButton
        qa={'app-app-button-start'}
        variant={APP_BUTTON_VARIANTS.SOLID}
        onClick={navigateToWebApp}
      >
        {t('buttons.start')}
      </AppButton>
    </>
  );
};
