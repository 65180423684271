import { AppLink, TAppLinkProps, UniconsChevronRight } from '@codeer/handypark-web-common';

type TLegendLinkProps = TAppLinkProps & {
  id: string;
};

export const LegendLink = ({ id, children, ...rest }: TLegendLinkProps) => {
  return (
    <AppLink href={`#${id}`} {...rest}>
      <UniconsChevronRight fontSize={'0.6em'} me={2} />
      {children}
    </AppLink>
  );
};
