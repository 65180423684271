import { A11Y_STATEMENT_IDS } from '@features/legal';

export const A11Y_BLOCK_ORDER: A11Y_STATEMENT_IDS[] = [
  A11Y_STATEMENT_IDS.LAW,
  A11Y_STATEMENT_IDS.COMPLIANCE_STATUS,
  A11Y_STATEMENT_IDS.NON_ACCESSIBLE_CONTENT,
  A11Y_STATEMENT_IDS.PREPARATION,
  A11Y_STATEMENT_IDS.COMPATIBILITY_BROWSERS_AND_SOFTWARE,
  A11Y_STATEMENT_IDS.TECHNICAL_SPECS,
  A11Y_STATEMENT_IDS.IMPROVEMENTS_A11Y,
  A11Y_STATEMENT_IDS.A11Y_DOCUMENTS,
  A11Y_STATEMENT_IDS.FEEDBACK_AND_CONTACT,
  A11Y_STATEMENT_IDS.EXTERNAL_HELP,
];
