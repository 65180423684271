import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { COLOR_SCHEMES } from '@codeer/handypark-web-common';

const helpers = createMultiStyleConfigHelpers(['container', 'nav']);

export const handyParkRetributionRegulationsLegend = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      order: { base: -1, md: 1 },
      flexGrow: 1,
      minWidth: '2xs',
      w: { base: 'full', md: 'unset' },
      pl: { base: 0, md: 12 },
      pr: { base: 0, md: 6 },
      pt: { base: 6, md: 6 },
      pb: 6,
      borderLeft: { base: 'none', md: '0.0625em solid' },
      borderLeftColor: `${COLOR_SCHEMES.SHADE}.300`,
      borderBottom: { base: '0.0625em solid', md: 'none' },
      borderBottomColor: `${COLOR_SCHEMES.SHADE}.300`,
      position: 'sticky',
      top: 0,
      background: `${COLOR_SCHEMES.BODY}.background`,
      alignSelf: 'flex-start',
      maxHeight: '75dvh',
      overflowY: 'auto',
    },
    nav: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: { base: 'row', md: 'column' },
      gap: 4,
    },
  },
});
