import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppHeading, HEADING_STYLING_KEYS, TAppBoxProps } from '@codeer/handypark-web-common';
import { APP_COMPONENT_NAMES } from '@core';
import {
  currentRegionAtom,
  RetributionRegulationsLegendLink,
  TMappedMunicipalitiesToRegionsAndProvinces,
} from '@features/retribution-regulations';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

type TRetributionRegulationsLegendProps = TAppBoxProps & {
  data: TMappedMunicipalitiesToRegionsAndProvinces;
};

export const RetributionRegulationsLegend = ({
  qa = 'retribution-regulations-legend',
  data,
  sx,
  ...rest
}: TRetributionRegulationsLegendProps) => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'retribution-regulations.legend',
  });
  const { t: tRegion } = useTranslation('municipalities', { keyPrefix: 'regions' });

  /* --- ATOMS --- */
  const currentRegion = useAtomValue(currentRegionAtom);

  /* --- HOOKS --- */
  const styles = useMultiStyleConfig(APP_COMPONENT_NAMES.RETRIBUTION_REGULATIONS_LEGEND);

  return (
    <Box
      data-qa={qa}
      sx={{ ...styles.container, ...sx }}
      {...rest}
      maxHeight={'100dvh'}
      overflowY={'scroll'}
    >
      <AppHeading as={HEADING_STYLING_KEYS.H2} textStyle={HEADING_STYLING_KEYS.H2} mb={6}>
        {t('title')}
      </AppHeading>

      <Box as={'nav'} sx={styles.nav}>
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {Object.entries(data).map(([region, _]) => {
          return (
            <RetributionRegulationsLegendLink
              key={region}
              id={region}
              href={region}
              text={tRegion(region)}
              isActive={region === currentRegion}
            />
          );
        })}
      </Box>
    </Box>
  );
};
