import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { COLOR_SCHEMES, themeOutlineOffsets } from '@codeer/handypark-web-common';

/*
 * Documentation:
 * https://chakra-ui.com/docs/components/progress/theming
 * */

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

const baseStyle = definePartsStyle({
  root: {
    '--tabs-color': `colors.${COLOR_SCHEMES.SHADE}.200`,
    '.chakra-tabs__tab-indicator': {
      mt: '-0.09375rem',
      height: '0.125rem',
      bg: `${COLOR_SCHEMES.PRIMARY}.main`,
      transitionDuration: '0ms !important',
    },
  },
  tablist: {
    display: 'inline-flex',
    borderBottom: '0.125rem solid',
    borderColor: `${COLOR_SCHEMES.PRIMARY}.background`,
  },
  tab: {
    display: 'inline-flex',
    flexDirection: 'row',
    gap: 3,
    alignItems: 'center',
    px: 4,
    py: 5,
    color: `${COLOR_SCHEMES.SHADE}.200`,
    textDecoration: 'none',
    textStyle: 'label',
    _hover: {
      '--tabs-color': `colors.${COLOR_SCHEMES.SHADE}.100`,
      color: `${COLOR_SCHEMES.SHADE}.100`,
    },
    _focusVisible: {
      outlineOffset: themeOutlineOffsets.neg_sm,
      outlineColor: `${COLOR_SCHEMES.PRIMARY}.main`,
      color: `${COLOR_SCHEMES.SHADE}.100`,
      boxShadow: 'none',
    },

    _selected: {
      fontWeight: 'bold',
      color: `${COLOR_SCHEMES.SHADE}.100`,
    },
  },
  tabpanels: {},
  tabpanel: {
    py: 8,
    px: 0,
  },
});

export const handyParkTabs = defineMultiStyleConfig({
  defaultProps: {
    size: 'md',
    colorScheme: COLOR_SCHEMES.PRIMARY,
  },
  baseStyle: baseStyle,
  sizes: {
    md: baseStyle,
  },
});
