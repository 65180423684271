import { Box } from '@chakra-ui/react';
import { AppLink, Paragraph, useGetMunicipalityTranslations } from '@codeer/handypark-web-common';
import { TMunicipality } from '@features/municipalities';

type TRetributionRegulationsMunicipalityProps = {
  municipality: TMunicipality;
};

export const RetributionRegulationsMunicipality = ({
  municipality,
}: TRetributionRegulationsMunicipalityProps) => {
  const { key, retributionRegulationUrl } = municipality;

  /* --- HOOKS --- */
  const tMunicipality = useGetMunicipalityTranslations(key);

  return (
    <>
      <Box>
        {retributionRegulationUrl ? (
          <AppLink aria-label={tMunicipality} href={retributionRegulationUrl} qa={'municipality'}>
            {tMunicipality}
          </AppLink>
        ) : (
          <Paragraph fontSize={'1rem'} fontWeight={'semibold'}>
            {tMunicipality}
          </Paragraph>
        )}
      </Box>
    </>
  );
};
