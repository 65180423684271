import { MUNICIPALITY_PROVINCES } from '@codeer/handypark-web-common';
import {
  MUNICIPALITY_REGIONS,
  TMunicipalityProvince,
  TMunicipalityRegion,
} from '@features/municipalities';

export const mapProvinceToRegion = (province: TMunicipalityProvince): TMunicipalityRegion => {
  const mapping = {
    [MUNICIPALITY_PROVINCES.ANTWERPEN]: MUNICIPALITY_REGIONS.VLAANDEREN,
    [MUNICIPALITY_PROVINCES.OOST_VLAANDEREN]: MUNICIPALITY_REGIONS.VLAANDEREN,
    [MUNICIPALITY_PROVINCES.WEST_VLAANDEREN]: MUNICIPALITY_REGIONS.VLAANDEREN,
    [MUNICIPALITY_PROVINCES.VLAAMS_BRABANT]: MUNICIPALITY_REGIONS.VLAANDEREN,
    [MUNICIPALITY_PROVINCES.LIMBURG]: MUNICIPALITY_REGIONS.VLAANDEREN,
    [MUNICIPALITY_PROVINCES.HENEGOUWEN]: MUNICIPALITY_REGIONS.WALLONIE,
    [MUNICIPALITY_PROVINCES.LUIK]: MUNICIPALITY_REGIONS.WALLONIE,
    [MUNICIPALITY_PROVINCES.LUXEMBURG]: MUNICIPALITY_REGIONS.WALLONIE,
    [MUNICIPALITY_PROVINCES.NAMEN]: MUNICIPALITY_REGIONS.WALLONIE,
    [MUNICIPALITY_PROVINCES.WAALS_BRABANT]: MUNICIPALITY_REGIONS.WALLONIE,
    [MUNICIPALITY_PROVINCES.BRUSSELS_HOOFDSTEDELIJK_GEWEST]:
      MUNICIPALITY_REGIONS.BRUSSELS_HOOFDSTEDELIJK_GEWEST,
  };

  return mapping[province];
};
