import { Box } from '@chakra-ui/react';
import { TAppBoxProps } from '@codeer/handypark-web-common';

type TLegendLinkContainerProps = TAppBoxProps;

export const LegendLinkContainer = ({ children, ...rest }: TLegendLinkContainerProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'flex-start'} {...rest}>
      {children}
    </Box>
  );
};
