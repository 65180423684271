import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppTrans, Paragraph } from '@codeer/handypark-web-common';
import { AppTransLink } from '@features/legal';
import { useTranslation } from 'react-i18next';

export const PrivacyContactInfo = () => {
  const keyPrefix = 'legal.privacy-contact-info';
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.COMMON, { keyPrefix });

  return (
    <>
      <Paragraph>
        <AppTrans
          i18nKey={`${keyPrefix}.oversight-committee.paragraph1`}
          ns={T_NAMESPACES.COMMON}
          components={{
            website: <AppTransLink t={t} baseKey={'oversight-committee.paragraph1-website'} />,
            address: (
              <AppTrans
                i18nKey={`${keyPrefix}.oversight-committee.paragraph1-address`}
                ns={T_NAMESPACES.COMMON}
              />
            ),
            tel: <AppTransLink t={t} baseKey={'oversight-committee.paragraph1-tel'} />,
            mail: <AppTransLink t={t} baseKey={'oversight-committee.paragraph1-mail'} />,
          }}
        />
      </Paragraph>

      <Paragraph>
        <AppTrans
          i18nKey={`${keyPrefix}.data-protection-authority.paragraph1`}
          ns={T_NAMESPACES.COMMON}
          components={{
            website: (
              <AppTransLink t={t} baseKey={'data-protection-authority.paragraph1-website'} />
            ),
            address: (
              <AppTrans
                i18nKey={`${keyPrefix}.data-protection-authority.paragraph1-address`}
                ns={T_NAMESPACES.COMMON}
              />
            ),
            tel: <AppTransLink t={t} baseKey={'data-protection-authority.paragraph1-tel'} />,
            fax: <AppTransLink t={t} baseKey={'data-protection-authority.paragraph1-fax'} />,
            mail: <AppTransLink t={t} baseKey={'data-protection-authority.paragraph1-mail'} />,
          }}
        />
      </Paragraph>
    </>
  );
};
