import { IRouteConfig } from '@codeer/handypark-web-common';
import { HELP_AND_SUPPORT_ROUTES } from '@features/help-and-support';
import { lazy } from 'react';

const HelpAndSupportPage = lazy(() =>
  import('@features/help-and-support/pages').then(module => ({
    default: module.HelpAndSupportPage,
  })),
);

export const helpAndSupportRoutes: () => IRouteConfig[] = () => [
  {
    path: HELP_AND_SUPPORT_ROUTES().HELP_AND_SUPPORT(),
    component: HelpAndSupportPage,
  },

  {
    path: `${HELP_AND_SUPPORT_ROUTES().HELP_AND_SUPPORT()}/*`,
    redirect: HELP_AND_SUPPORT_ROUTES().HELP_AND_SUPPORT(),
  },
];
