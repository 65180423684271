import { COOKIE_POLICY_IDS } from '@features/legal';

export const COOKIE_POLICY_BLOCK_ORDER: COOKIE_POLICY_IDS[] = [
  COOKIE_POLICY_IDS.RESPONSIBLE,
  COOKIE_POLICY_IDS.FURTHER_QUESTIONS,
  COOKIE_POLICY_IDS.WHAT_ARE_COOKIES,
  COOKIE_POLICY_IDS.USED_COOKIES,
  COOKIE_POLICY_IDS.MANAGE_COOKIES,
  COOKIE_POLICY_IDS.DATA_SHARING,
  COOKIE_POLICY_IDS.EU_DATA_TRANSFERS,
  COOKIE_POLICY_IDS.DATA_RETENTION,
  COOKIE_POLICY_IDS.DATA_SECURITY,
  COOKIE_POLICY_IDS.USER_RIGHTS,
];
