import { customAppComponents } from '@core';

import { handyParkTabs as Tabs } from './components';

/*
 * Documentation:
 * - https://chakra-ui.com/docs/styled-system/component-style
 * - https://chakra-ui.com/docs/components > component > theming
 * */

export const themeComponents = {
  Tabs,

  // Custom created components
  ...customAppComponents,
};
