export enum A11Y_STATEMENT_IDS {
  LAW = 'law',
  COMPLIANCE_STATUS = 'compliance-status',
  NON_ACCESSIBLE_CONTENT = 'non-accessible-content',
  PREPARATION = 'preparation',
  COMPATIBILITY_BROWSERS_AND_SOFTWARE = 'compatibility-browsers-and-software',
  TECHNICAL_SPECS = 'technical-specs',
  IMPROVEMENTS_A11Y = 'improvements-a11y',
  A11Y_DOCUMENTS = 'a11y-documents',

  FEEDBACK_AND_CONTACT = 'feedback-and-contact',
  EXTERNAL_HELP = 'external-help',
}
