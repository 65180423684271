import {
  currentRegionAtom,
  RetributionRegulationsData,
  RetributionRegulationsLegend,
  TMappedMunicipalitiesToRegionsAndProvinces,
  useRegionObserver,
} from '@features/retribution-regulations';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';

type TRetributionRegulationsContainerProps = {
  data: TMappedMunicipalitiesToRegionsAndProvinces;
};

export const RetributionRegulationsContainer = ({
  data,
}: TRetributionRegulationsContainerProps) => {
  /* --- ATOMS --- */
  const setCurrentRegion = useSetAtom(currentRegionAtom);

  /* --- HOOKS --- */
  const regions = useMemo(() => {
    return Object.keys(data);
  }, [data]);
  const currentRegion = useRegionObserver(regions);

  /* --- ACTIONS */
  useEffect(() => {
    setCurrentRegion(currentRegion);
  }, [currentRegion, setCurrentRegion]);

  return (
    <>
      <RetributionRegulationsData data={data} />
      <RetributionRegulationsLegend data={data} />
    </>
  );
};
