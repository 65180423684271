import { ImageProps } from '@chakra-ui/react';
import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { ImagePreloader } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';
import landingHelpAndSupportImage from './landing-help-and-support.png?format=webp&imagetools';

type Props = Qa & ImageProps;

export const LandingHelpAndSupportImage = ({
  qa = 'retribution-regulations-help-and-support-image',
  ...rest
}: Props) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES);

  const { htmlWidth, htmlHeight } = {
    htmlWidth: 926,
    htmlHeight: 629,
  };
  const ratio = Number((Number(htmlWidth) / Number(htmlHeight)).toFixed(2));

  const maxHeight = 17;
  const maxWidth = maxHeight * ratio;

  return (
    <ImagePreloader
      image={{
        src: landingHelpAndSupportImage,
        alt: t('onboarding.2'),
        htmlWidth,
        htmlHeight,

        // h,
        w: 'full',

        maxHeight: `${maxHeight}rem`,
        maxWidth: `${maxWidth}rem`,

        ...rest,
      }}
      qa={qa}
    />
  );
};
