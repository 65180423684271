import { IRouteConfig } from '@codeer/handypark-web-common';
import { LEGAL_ROUTES } from '@features/legal';
import { lazy } from 'react';

const PrivacyStatementPage = lazy(() =>
  import('@features/legal/pages').then(module => ({
    default: module.PrivacyStatementPage,
  })),
);
const AccessibilityStatementPage = lazy(() =>
  import('@features/legal/pages').then(module => ({
    default: module.AccessibilityStatementPage,
  })),
);

const CookiePolicyPage = lazy(() =>
  import('@features/legal/pages').then(module => ({
    default: module.CookiePolicyPage,
  })),
);

export const legalRoutes: () => IRouteConfig[] = () => [
  {
    path: LEGAL_ROUTES().PRIVACY_STATEMENT(),
    component: PrivacyStatementPage,
  },
  {
    path: `${LEGAL_ROUTES().PRIVACY_STATEMENT()}/*`,
    redirect: LEGAL_ROUTES().PRIVACY_STATEMENT(),
  },

  {
    path: LEGAL_ROUTES().A11Y_STATEMENT(),
    component: AccessibilityStatementPage,
  },
  {
    path: `${LEGAL_ROUTES().A11Y_STATEMENT()}/*`,
    redirect: LEGAL_ROUTES().A11Y_STATEMENT(),
  },

  {
    path: LEGAL_ROUTES().COOKIE_POLICY(),
    component: CookiePolicyPage,
  },
  {
    path: `${LEGAL_ROUTES().COOKIE_POLICY()}/*`,
    redirect: LEGAL_ROUTES().COOKIE_POLICY(),
  },
];
