import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container']);

export const handyParkRetributionRegulationsData = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      w: 'full',
      flexGrow: { base: 1, md: 5 },
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      pt: 6,
      pb: 6,
      pr: { md: 12 },
    },
  },
});
