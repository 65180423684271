import { TabIndicator, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppTab, AppTabPanel, HEADING_STYLING_KEYS, Paragraph } from '@codeer/handypark-web-common';
import { useQueryState } from 'nuqs';
import { lazy } from 'react';
import { useTranslation } from 'react-i18next';

const HelpAndSupportApp = lazy(() =>
  import('./app').then(module => ({
    default: module.HelpAndSupportApp,
  })),
);
const HelpAndSupportWebsite = lazy(() =>
  import('./website').then(module => ({
    default: module.HelpAndSupportWebsite,
  })),
);

/*
 * Based on query param 'platform' we will set the default selected tab
 * */
export const HelpAndSupportTabs = () => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, { keyPrefix: 'help-and-support.tabs' });

  /* --- HOOKS --- */
  const [platform] = useQueryState('platform', { defaultValue: 'website' });

  /* --- STATE --- */
  const defaultIndex = platform === 'app' ? 0 : 1;

  return (
    <>
      <Paragraph textStyle={HEADING_STYLING_KEYS.H3} mb={6}>
        {t('title')}
      </Paragraph>
      <Tabs position='relative' isLazy defaultIndex={defaultIndex}>
        <TabList>
          <AppTab>{t('platforms.app.title')}</AppTab>
          <AppTab>{t('platforms.website.title')}</AppTab>
        </TabList>
        <TabIndicator />
        <TabPanels>
          <AppTabPanel>
            <HelpAndSupportApp />
          </AppTabPanel>
          <AppTabPanel>
            <HelpAndSupportWebsite />
          </AppTabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
