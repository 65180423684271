import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppTransLink } from '@features/legal';
import { useTranslation } from 'react-i18next';

type TBrowserLinkProps = {
  browser: string;
  manageCookies?: boolean;
};

export const BrowserLink = ({ browser, manageCookies = false }: TBrowserLinkProps) => {
  /* --- TRANSLATIONS --- */
  const { t: tBrowser } = useTranslation(T_NAMESPACES.COMMON, { keyPrefix: 'browsers' });

  if (manageCookies) {
    return <AppTransLink t={tBrowser} hrefPath={`${browser}.manage-cookies`} baseKey={browser} />;
  }

  return <AppTransLink t={tBrowser} baseKey={browser} />;
};
