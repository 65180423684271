import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { TAppBoxProps } from '@codeer/handypark-web-common';
import { APP_COMPONENT_NAMES } from '@core';
import {
  RetributionRegulationsRegion,
  TMappedMunicipalitiesToRegionsAndProvinces,
} from '@features/retribution-regulations';

type TRetributionRegulationsDataProps = TAppBoxProps & {
  data: TMappedMunicipalitiesToRegionsAndProvinces;
};

export const RetributionRegulationsData = ({
  data,
  sx,
  ...rest
}: TRetributionRegulationsDataProps) => {
  /* --- HOOKS --- */
  const styles = useMultiStyleConfig(APP_COMPONENT_NAMES.RETRIBUTION_REGULATIONS_DATA);

  return (
    <Box sx={{ ...styles.container, ...sx }} {...rest} gap={24}>
      {Object.entries(data).map(([region, provinces]) => {
        return <RetributionRegulationsRegion key={region} region={region} provinces={provinces} />;
      })}
    </Box>
  );
};
