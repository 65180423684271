import { AppLink } from '@codeer/handypark-web-common';
import { TFunction } from 'i18next';

type TAppTransLinkProps = {
  t: TFunction;
  baseKey: string;
  hrefPath?: string;
};

export const AppTransLink = ({ t, baseKey, hrefPath }: TAppTransLinkProps) => {
  return (
    <AppLink
      href={hrefPath ? t(`${hrefPath}.url`) : t(`${baseKey}.url`)}
      aria-label={t(`${baseKey}.text`)}
    >
      {t(`${baseKey}.text`)}
    </AppLink>
  );
};
