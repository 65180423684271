import { BrowserLink } from '@features/legal';

const browsers = ['safari', 'ie', 'firefox', 'chrome', 'edge', 'microsoft-clarity'] as const;

export const browserLinkComponents = Object.freeze(
  browsers.reduce((acc, browser) => {
    acc[`browser-link-${browser}`] = <BrowserLink browser={browser} />;
    acc[`browser-link-${browser}-cookies`] = <BrowserLink browser={browser} manageCookies={true} />;
    return acc;
  }, {} as Record<string, JSX.Element>),
);
