import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppLink } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

export const PocOmbudsMail = () => {
  /**/
  const { t } = useTranslation(T_NAMESPACES.COMMON, {
    keyPrefix: 'legal.p-o-c.ombuds.mail',
  });

  return (
    <AppLink href={t('url')} aria-label={t('text')}>
      {t('text')}
    </AppLink>
  );
};
