export enum COOKIE_POLICY_IDS {
  RESPONSIBLE = 'responsible',
  FURTHER_QUESTIONS = 'further-questions',
  WHAT_ARE_COOKIES = 'what-are-cookies',
  USED_COOKIES = 'used-cookies',
  MANAGE_COOKIES = 'manage-cookies',
  DATA_SHARING = 'data-sharing',
  EU_DATA_TRANSFERS = 'eu-data-transfers',
  DATA_RETENTION = 'data-retention',
  DATA_SECURITY = 'data-security',
  USER_RIGHTS = 'user-rights',
}
