import { useColorMode } from '@chakra-ui/react';
import { COLOR_SCHEMES, isLightMode } from '@codeer/handypark-web-common';
import {
  LandingHelpAndSupport,
  LandingHero,
  LandingHowToUse,
  LandingPartners,
  LandingWhereToPark,
} from '@features/landing';

import './landing.style.scss';

export const Landing = () => {
  const { colorMode } = useColorMode();

  return (
    <>
      {/*
       * First section needs to be pushed up to be behind the header
       */}
      <LandingHero pt={16} mt={-16} background={`${COLOR_SCHEMES.PRIMARY}.background`} />
      <LandingHelpAndSupport background={`${COLOR_SCHEMES.BODY}.background`} />
      <LandingHowToUse background={`${COLOR_SCHEMES.PRIMARY}.background`} />
      <LandingWhereToPark background={`${COLOR_SCHEMES.BODY}.background`} />
      <LandingPartners
        background={`${COLOR_SCHEMES.PRIMARY}.background`}
        borderBottom={!isLightMode(colorMode) ? `2px` : 'none'}
        borderBottomColor={`${COLOR_SCHEMES.BODY}.background`}
      />
    </>
  );
};
